<template>
  <div>
    <el-card>
      <div>
        <span class="yuan">筛选条件</span>
      </div>
      <div class="heng"></div>
      <div class="form-Data">
        <el-form ref="form" :model="form" label-width="140px">
          <el-row>
            <el-col :span="6">
              <el-form-item label="姓名">
                <el-input v-model="form.merchantName" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="身份证号">
                <el-input v-model="form.idCard" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="手机号">
                <el-input v-model="form.phone" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="银行卡号">
                <el-input v-model="form.bankCard" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="创建时间">
                <div class="block">
                  <el-date-picker
                    v-model="form.value1"
                    type="daterange"
                    align="right"
                    clearable
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="来源">
                <el-select v-model="form.source" clearable>
                  <el-option
                    v-for="item in sourceList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="签约状态">
                <el-select v-model="form.contract" clearable>
                  <el-option
                    v-for="item in contractList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item>
                    <el-button type="primary" @click="querySearch()">查询</el-button>
                    <el-button type="primary" @click="exporthandleclick()">导出</el-button>
                    <el-button type="primary" @click="SendMessages()">下发短信</el-button>
                </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <el-card style="margin-top:15px">
        <el-table
       v-loading="load"
      :header-cell-style="{background:'#9e99b9',color:'#ffff'}"
      :data="tableData"
      style="width: 100%;margin-top: 10px">
      <el-table-column
        header-align="center"
        align="center"
        prop="name"
        width="130"
        label="姓名">
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="idCard"
        label="身份证号">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="bankNum"
        width="230"
        label="银行账号">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="phone"
        width="150"
        label="手机号">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="email"
        label="邮箱">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="operationTime"
        label="创建时间">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="sourceText"
        label="来源">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="isRealNameText"
        width="130"
        label="是否实名">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="signStatusText"
        width="130"
        label="签约状态">
      </el-table-column>
      <el-table-column
          header-align="center"
          align="center"
          label="操作"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button @click="seeDetails(scope.row.individualBusinessUserId)" type="text">查看详情</el-button>
            <!-- <el-button @click="newDelete(scope.row.mid)">删除</el-button> -->
          </template>
        </el-table-column>
    </el-table>
    <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="currPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalCount"
        style="text-align:right;margin-top:10px"
        class="text_center"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import { infoPageList , source , contract} from '@/api/individual'
export default {
  data() {
    return {
      load:false,
      // 分页
      pageSize: 10,
      totalPage: 0,
      totalCount: 0,
      currPage: 1,
      tableData:[],
      form: {
          merchantName:'',//姓名
          phone:'',//手机号
          idCard:'',//身份证号
          bankCard:'',//银行卡号
          value1:'',//创建时间
          source:'',//来源
          contract:'',//签约状态
      },
      sourceList: [],//来源列表
      contractList: [],//签约状态列表
    };
  },
  mounted(){
    this.getDataList();
    this.source1();
    this.contract1();
  },
  methods: {
      // 来源
    source1(){
        source({
            type:'BUSINESS_TASK_LOG_APPLY_TYPE'
        }).then((data) => {
            console.log(data,'data789');
            if(data && data.code == 200) {
                this.sourceList = data.data
            }else {
                this.sourceList = []
            }
        })
    },
    // 签约状态
    contract1(){
        contract({
            type:'BUSINESS_TASK_LOG_STATE'
        }).then((data) => {
            if(data && data.code == 200) {
                this.contractList = data.data
            }else {
                this.contractList = []
            }
        })
    },
    // 查询
    querySearch(){
        this.currPage = 1
        this.getDataList()
    },
    // 导出
    formatJson(filterVal, list) {
      return list.map(item =>
        filterVal.map(ite => {
          return item[ite];
        })
      );
    },
    exporthandleclick(){
      let params = {
            bankNum:this.form.bankCard,
            name:this.form.merchantName,
            idCard:this.form.idCard,
            phone:this.form.phone,
            operationTimeBegin:this.form.value1==""||this.form.value1==null ? "" : (this.form.value1[0] + ' 00:00:00 '),
            operationTimeEnd:this.form.value1==""||this.form.value1==null? "" : (this.form.value1[1] + ' 23:59:59 '),
            source:this.form.source,
            receiveStatus:this.form.contract,
            current: 1,
            size: 10000,
        }
      infoPageList(params).then((data) => {
            //表格的表头列表
            const tHeader = [
              "姓名",
              "身份证号",
              "银行账号",
              "手机号",
              "邮箱",
              "创建时间",
              "来源",
              "是否实名",
              "签约状态",
            ]; //与表头相对应的数据里边的字段
            const filterVal = [
              "name",
              "idCard",
              "bankNum",
              "phone",
              "email",
              "operationTime",
              "sourceText",
              "isRealNameText",
              "signStatusText",
            ];
            const list = data.data.records;
            this.importAllData(tHeader, filterVal, list)
      })
    },
    importAllData (tHeader, filterVal, list) {
				import('@/excel/Export2Excel').then(excel => {
					const data = this.formatJson(filterVal, list)
					//这里还是使用export_json_to_excel方法比较好，方便操作数据
					excel.export_json_to_excel(tHeader, data, '人员列表')
				})
    },
    // 下发短信
    shortMessage(){},
    // 获取列表
    getDataList(){
        this.load=true
        let params = {
            bankNum:this.form.bankCard,
            name:this.form.merchantName,
            idCard:this.form.idCard,
            phone:this.form.phone,
            operationTimeBegin:this.form.value1==""||this.form.value1==null ? "" : (this.form.value1[0] + ' 00:00:00 '),
            operationTimeEnd:this.form.value1==""||this.form.value1==null? "" : (this.form.value1[1] + ' 23:59:59 '),
            source:this.form.source,
            receiveStatus:this.form.contract,
            current: this.currPage,
            size: this.pageSize,
        }
        infoPageList(params).then((data) => {
            if(data && data.code == 200) {
                this.load = false
                this.tableData = data.data.records
                this.totalCount = data.data.total
            } else {
                this.tableData = []
                this.totalCount = ''
            }
        })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.currPage = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.currPage = val;
      this.getDataList();
    },
     SendMessages(){
      this.$router.push({path:"/individual-SendMessages"})
    },
      // 查看详情
    seeDetails(a){
      var newrou=this.$router.resolve({name: '/personnellist', query:{Id:a}})
      window.open(newrou.href,'_blank')
      // this.$router.push({name:"/personnellist",query:{Id:a}})
    },
  },
};
</script>

<style scoped>
.yuan {
  font-size: 12px;
}
.heng {
  height: 1px;
  margin-top: 10px;
  background-color: #d5d5d5;
  overflow: hidden;
}
.form-Data {
  margin-top: 30px;
}
.btn {
  display: inline-block;
  width: 20%;
}
</style>